import API from 'o365.modules.data.api.ts';

export interface IUserSession {
    formats: any;
    amDesignator?: string,
    culture?: string,
    dayNames?: string[],
    dayNamesShort?: string[],
    decimalSeparator?: string,
    emailAddress?: string,
    firstName?: string,
    groupSeparator?: string,
    isDeveloper?: boolean,
    language?: string,
    lastName?: string,
    login?: string,
    mobileNumber?: string,
    monthNames?: string[],
    monthNamesShort?: string[],
    name?: string,
    personId?: number,
    pmDesignator?: string,
    shortDateFormat?: string,
    uiCulture?: string,
    userType?: string,
    [key: string]: unknown;
}

export interface IDataObjectConfig {
    id: string;
    appId?: string;
    viewName: string;
    distinctRows: boolean;
    uniqueTable: string;
    allowUpdate: boolean;
    allowInsert: boolean;
    allowDelete: boolean;
    appendData: boolean;
    selectFirstRowOnLoad: boolean;
    fields: Array<IDataObjectFieldConfig>;
    masterDataObject_ID: string;
    masterDetailDefinition: Array<IDataObjectMasterDetailDefinition>;
    clientSideHandler: string;
    maxRecords: number;
    dynamicLoading: boolean;
    whereClause: string;
    filterString: string;
    // [key: string]: unknown;
}

export interface IDataObjectFieldConfig {
    name: string;
    sortOrder: number;
    sortDirection: string;
    groupByOrder: number;
    groupByAggregate: string;
    [key: string]: unknown;
}

export interface IDataObjectMasterDetailDefinition {
    detailField: string;
    operator: string;
    masterField: string;
    [key: string]: unknown;
}

export interface IViewDefinitionField {
    fieldName: string;
    maxLength: number;
    dataType: string;
    computed: boolean;
    identity: boolean;
    hasDefault: boolean;
    nullable: boolean;
};

interface IAppInfo {
    id: string;
    isDebug: boolean;
    config?: string;
    dataObjects: IDataObjectConfig[];
    lastModified: string;
    viewDefinition: Record<string, IViewDefinitionField | undefined>;
};

interface IAppConfig {
    appDependencies?: Array<string>;
    CTAppID?: string;
    previewURL?: string;
    setupApp?: boolean;
    pwaSettings?: IAppConfigPwaSettings
    mobile?: IAppConfigMobileSettings
    [key: string]: any;
}

interface IAppConfigPwaSettings {
    title?: string;
    icon?: string;
    serviceWorkerId: string;
    entrypoint?: string;
    manifestId?: string;
}

interface IAppConfigMobileSettings {
    route: string;
    icon: string;
    async: boolean;
}

interface IApp {
    id: string;
    parentId?: string;
    parentTitle?: string;
    title?: string;
    fingerprint: string;
    db_objectfingerprint: string;
    proxyRequest?: string;
    filters: any;
    dataObjectConfigs: Map<string, IDataObjectConfig>;
    filterTemplates: any;
    viewDefinitions: Record<string, IViewDefinitionField | undefined>;
    isDebug: boolean;
    config: IAppConfig | null;
}

interface ISiteSettings {
    dataGrid: {
        defaultSidePanelPosition: 'left' | 'right',
        allowSidePanelSwitching: boolean
    }
}

let userSession: IUserSession = {
    //we should replace this with default intl format
    formats: {
        "$": "$0 000",
        "$.00": "$0 000.00",
        "%": "0%",
        "*": "*",
        "/1000.0": "/1000.0",
        "/1000000.0": "/1000000.0",
        "0.0%": "0.0%",
        "1 234": "0 000",
        "1 234.1": "0 000.0",
        "1 234.12": "0 000.00",
        "1 234.1234": "0 000.0000",
        "123.12b": "0.00b",
        "123.12k": "0.00k",
        "123.1b": "0.0b",
        "123.1k": "0.0k",
        "dd.MM": "dd.MM",
        "dd.MM.yy": "dd.MM.yy",
        "dd.MM.yyyy": "dd.MM.yyyy",
        "dd.MM.yyyy HH:mm": "dd.MM.yyyy HH:mm",
        "dd.MMM": "dd.MMM",
        "dd.MMM.yyyy": "dd.MMM.yyyy",
        "dd.MMM.yyyy HH:mm": "dd.MMM.yyyy HH:mm",
        "Full Date Short Time": "dd MMMM yyyy HH:mm",
        "General Date Long Time": "dd/MM/yyyy HH:mm:ss",
        "General Date Short Time": "dd/MM/yyyy HH:mm",
        "HH:mm": "HH:mm",
        "Long Date": "dd MMMM yyyy",
        "Long Time": "HH:mm:ss",
        "MM.yyyy": "MM.yyyy",
        "Short Date": "dd/MM/yyyy",
        "Short Time": "HH:mm",
        "sql_date_time": "yyyy-MM-dd HH:mm:ss",
        "yyyymmdd": "yyyyMMdd",
        "yyyy-MM-dd": "yyyy-MM-dd"
    }
};

const app: IApp = {
    id: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-id"]'))?.content,
    parentId: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-parentid"]'))?.content,
    parentTitle: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-parenttitle"]'))?.content,
    title: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-title"]'))?.content,
    fingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-fingerprint"]'))?.content,
    db_objectfingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-dbobjectdefinition-fingerprint"]'))?.content,
    proxyRequest: (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content,
    filters: {},
    dataObjectConfigs: new Map<string, IDataObjectConfig>(),
    filterTemplates: {},
    viewDefinitions: {} as Record<string, IViewDefinitionField | undefined>,
    isDebug: false,
    config: null
}

const userFingerPrint = (<HTMLMetaElement>document.querySelector('meta[name="o365-person-lastloggedin"]'))?.content;

const site = {
    fingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-staticfiles-fingerprint"]'))?.content,
    oldGenUrl: (<HTMLMetaElement>document.querySelector('meta[name="o365-site-oldgenurl"]'))?.content
}

const localSiteSettings: Partial<ISiteSettings> = {};

/** 
 * Backend returns unexpected time formats for nb-NO locale (HH.mm.ss instead of HH:mm:ss)
 * Temp workaround to replace the wrong formats
 */
function correctNbFormats(_formats: any = {}) {
    if (_formats == null) { return; }
    const formats = { ..._formats };
    const formatsToReplace = ['Full Date Short Time', 'General Date Long Time', 'General Date Short Time', 'Long Time', 'Short Time'];
    formatsToReplace.forEach(format => {
        const currentFormat: string = formats[format]
        if (currentFormat == null) { return; }
        formats[format] = currentFormat.replace('HH.mm', 'HH:mm').replace('mm.ss', 'mm:ss').replace('dddd', 'iiii').replace('tt', 'aa');
    });
    return formats;
}

async function initialize() {
    if (window['af']) { return; }
    const fingerPrint = app.db_objectfingerprint > app.fingerprint ? app.db_objectfingerprint : app.fingerprint;


    const appInfo: IAppInfo = await API.request({ requestInfo: `/nt/api/apps/${app.id}.${fingerPrint}.json`, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON });
    const localSettingsPromise = loadLocalSiteSettings();

    app.viewDefinitions = appInfo.viewDefinition;

    app.isDebug = appInfo.isDebug ?? false;

    appInfo.dataObjects?.forEach((dataObject: any) => {
        app.dataObjectConfigs.set(dataObject.id, dataObject);
    });

    if (app.dataObjectConfigs.size > 0 && (!app.viewDefinitions || Object.keys(app.viewDefinitions).length === 0)) {
        // App has DataObjects but no view definitions were returned
        const warningMessage = `${app.id}.json has DataObject definitions but no view definitions. Most likely one of the DataObjects has invalid or unset view`;
        if (app.isDebug) {
            import('o365.controls.alert.ts').then((alertModule) => {
                alertModule.default(warningMessage, 'warning', { autohide: true, delay: 10000, });
            });
        } else {
            console.warn(warningMessage);
        }
    }
    try {
        if (appInfo.config) {
            const appConfig: IAppConfig = JSON.parse(appInfo.config);

            app.config = appConfig;

            if (!appConfig.appDependencies?.some(id => id === 'o365-vue')) {
                const promises = appConfig.appDependencies?.map((appDependency) => {
                    return loadExternalAppConfig(appDependency);
                });

                if (promises) {
                    await Promise.all(promises);
                }
            }
        }
    } catch (error) {
        console.warn(error);
    }
    if (!userFingerPrint) { return; }
    if (userFingerPrint === '0') { return; }
    const originalFormats = userSession.formats;
    try {
        userSession = await API.request({ requestInfo: `/nt/api/usersession.${userFingerPrint}.json`, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON, headers: new Headers({ 'X-NT-API': 'true' }) });
        const formats = correctNbFormats(userSession.formats);
        if (formats && Object.keys(formats).length > 0) {
            userSession.formats = formats;
        }
    } catch (error) {
        console.warn("Failed to parse user session: ", error);
    } finally {
        if (userSession.formats == null) {
            userSession.formats = originalFormats;
        }
    }

    await localSettingsPromise;

    try {
        appendWarningBanner();
    } catch (ex) {

    }
}

function getAppConfig() {
    return app;
}

const extarnalAppsConfigs = new Map<string, IAppInfo>();

/**
 * Load configuration for a dependency app
 */
async function loadExternalAppConfig(pAppId: string) {
    if (!pAppId) { return; }

    if (!extarnalAppsConfigs.has(pAppId)) {
        const fingerPrint = app.db_objectfingerprint > app.fingerprint ? app.db_objectfingerprint : app.fingerprint;

        const appInfo: IAppInfo = await API.request({ requestInfo: `/nt/api/apps/${pAppId}.${fingerPrint}.json`, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON });
        appInfo.viewDefinitions = appInfo.viewDefinition;
        appInfo.dataObjectConfigs = new Map<string, IDataObjectConfig>();

        appInfo.dataObjects?.forEach((dataObject: any) => {
            appInfo.dataObjectConfigs.set(dataObject.id, dataObject);
        });

        extarnalAppsConfigs.set(pAppId, appInfo);

        try {
            if (appInfo.config) {
                const appConfig: {
                    appDependencies?: string[]
                } = JSON.parse(appInfo.config);
                const promises = appConfig.appDependencies?.map(async (appDependency) => {
                    await loadExternalAppConfig(appDependency);
                });
                if (promises) {
                    await Promise.all(promises);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }
}

function getExternalAppConfig(pAppId: string) {
    return extarnalAppsConfigs.get(pAppId);
}

function getUserSession() {
    return userSession;
}

await initialize();

function deprecationCheck() {
    if (app.isDebug && document.querySelector<HTMLMetaElement>('meta[name="o365-libraries"]')?.content === 'true') {
        import('o365-vue-services').then(services => {
            const message = `Something has caused an import of 'o365.modules.configs.ts'. This is not allowed on libaries template, all imports must be from libraries or sitesetup files that are using libraries\n\n`
                + `You can see the cause of the import by looking at the initiator in the network tab. (o365.modules.configs.ts is still available in prod)`
            services.alert(message);
        }).catch(_ex => {
            import('o365.controls.alert.ts').then(m => {
                const message = `Malformed app, libraries template used but 'o365-vue' is missing in appDependencies\n\n`
                    + `Something has caused an import of 'o365.modules.configs.ts'. This is not allowed on libaries template, all imports must be from libraries or sitesetup files that are using libraries\n\n`
                    + `You can see the cause of the import by looking at the initiator in the network tab. (o365.modules.configs.ts is still available in prod)`
                m.alert(message);

            }).catch(ex => {
                console.error(ex);
            });
        });
    }
}

if (app && app.isDebug) {
    deprecationCheck();
}

function appendWarningBanner() {
    if (!userSession.isDeveloper) { return; }
    if (app == null || !app.isDebug || document.querySelector<HTMLMetaElement>('meta[name="o365-libraries"]')?.content === 'true') { return; }
    // exception for devtools
    if (app.id.includes('appdesigner') || app.id.includes('sitesetup') || app.id == 'multi-file-compare') { return; }
    document.documentElement.style.setProperty('--o365-app-container-bottom', '35px');
    const banner = document.createElement('div');
    banner.classList.add('alert', 'alert-danger', 'w-100', 'position-absolute', 'm-0', 'py-1', 'text-truncate');
    banner.style.bottom = '0px';
    banner.style.height = '34px';
    banner.innerText = 'Apps with o365.modules template have stopped receiving support, please migrate to libraries';
    banner.title = 'Apps with o365.modules template have stopped receiving support, please migrate to libraries';
    document.body.append(banner);
}

function getLocalSiteSettings() {
    return localSiteSettings;
}

function getImportMapNode() {
    return document.querySelector('script[type="importmap"]') ?? document.querySelector('script[type="importmap-shim"]')
}


/**
 * Get site settings json with local overrides
 */
async function loadLocalSiteSettings() {
    const importMap = JSON.parse(getImportMapNode()?.innerHTML ?? '');
    const schemaUrl = importMap.imports['o365.jsonSchemas.settings.json'];
    const localJsonUrl = importMap.imports['local.settings.json'];

    let localOverrides: ISiteSettings | null = null;

    const generateJsonFromSchema = (pSchema: any) => {
        let result: any = {};
        if (pSchema.type === 'object' && pSchema.properties) {
            Object.keys(pSchema.properties).forEach(prop => {
                let propSchema = pSchema.properties[prop];
                if ('default' in propSchema) {
                    result[prop] = propSchema.default;
                } else if (propSchema.type === 'object') {
                    result[prop] = generateJsonFromSchema(propSchema);
                } else if (pSchema.required && pSchema.required.includes(prop)) {
                    result[prop] = null;
                }
            });
        }
        return result;
    }

    const merge = (targetObject: any, sourceObject: any) => {
        for (const key in sourceObject) {
            if (typeof sourceObject[key] === 'object' && !Array.isArray(sourceObject[key])) {
                if (targetObject[key]) {
                    targetObject[key] = merge(targetObject[key], sourceObject[key]);
                } else {
                    targetObject[key] = sourceObject[key];
                }
            } else {
                targetObject[key] = sourceObject[key];
            }
        }
        return targetObject;
    }


    const promises: Promise<void>[] = [];
    promises.push(
        API.request({ requestInfo: schemaUrl, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON})
            .then(schema => {
                const defaultSiteSettings = generateJsonFromSchema(schema);
                Object.assign(localSiteSettings, defaultSiteSettings);
            })
    );
    if (localJsonUrl) {
        promises.push(
            API.request({ requestInfo: localJsonUrl, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON })
                .then(json => {
                    localOverrides = json;
                })
        );
    }
    await Promise.all(promises);

    if (localOverrides && localSiteSettings) {
        merge(localSiteSettings, localOverrides);
    }
    return localSiteSettings;
}

export { getUserSession, getAppConfig, app, userSession, site, getExternalAppConfig, localSiteSettings, getLocalSiteSettings, initialize }
